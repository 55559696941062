import {
  Stack,
  TextField,
  Typography,
  useTheme,
  IconButton,
  InputAdornment,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from 'firebase/auth';
import {recordError, setIsAuthenticating} from '../App/actions';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {VisibilityOffRounded, VisibilityRounded} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {getErrorMessage} from '../../utils/errors';
import PageHeader from '../PageHeader';
import Validator from 'email-validator';

const AuthTypes = {
  EMAIL: 'email',
  PHONE: 'phone',
  CONFIRM: 'confirm',
  NONE: 'none',
};

const validatePhoneNumber = phone => {
  return /^[\+]?[0-9]{0,3}[\W]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    phone,
  );
};

const LoginForm = ({onToggleAuth}) => {
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState(false);
  const [authString, setAuthString] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [authType, setAuthType] = useState(AuthTypes.NONE);
  const confirmationResult = useRef(null);

  const dispatch = useDispatch();

  const auth = useRef(getAuth()).current;

  const navigate = useNavigate();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      size: 'invisible',
      callback: response => {
        console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
      },
    });
  }, []);

  useEffect(() => {
    const isValidEmail = Validator.validate(authString);

    if (isValidEmail) {
      setAuthType(AuthTypes.EMAIL);
      return;
    }

    const isValidPhoneNumber = validatePhoneNumber(authString);
    if (isValidPhoneNumber) {
      setAuthType(AuthTypes.PHONE);
      return;
    }
    if (authType !== AuthTypes.NONE) setAuthType(AuthTypes.NONE);
  }, [authString]);

  const handleLoginAsync = e => {
    e.preventDefault();
    setError(false);
    setIsWorking(true);

    switch (authType) {
      case AuthTypes.EMAIL:
        signInWithEmailAndPassword(auth, authString, password)
          .then((user, err) => {
            setIsWorking(false);
            if (!err) dispatch(setIsAuthenticating(false));
          })
          .catch(err => {
            setIsWorking(false);
            setError(getErrorMessage(err));
            dispatch(recordError(err), 'auth_error');
          });
        break;
      case AuthTypes.PHONE:
        signInWithPhoneNumber(auth, authString, window.recaptchaVerifier)
          .then(res => {
            confirmationResult.current = res;
            setAuthType(AuthTypes.CONFIRM);
          })
          .catch(err => {
            setIsWorking(false);
            setError(getErrorMessage(err));
            dispatch(recordError(err), 'auth_error');
          });
        break;
      default:
        setIsWorking(false);
        setError('Please enter a valid email or phone number.');
    }
  };

  const theme = useTheme();

  console.log(authType);

  return (
    <form onSubmit={handleLoginAsync}>
      <div>
        <PageHeader>Welcome Back</PageHeader>
        <Stack spacing={2}>
          {error && (
            <Typography variant="text" style={{color: theme.palette.red}}>
              {error}
            </Typography>
          )}
          {authType !== AuthTypes.CONFIRM && (
            <TextField
              required
              label="Phone Number or Email"
              type={AuthTypes.none}
              onChange={e => setAuthString(e.target.value)}
            />
          )}
          {authType === AuthTypes.CONFIRM && (
            <TextField
              autoFocus
              required
              label="Confirmation Code"
              type="number"
              onChange={e => {
                if (e.target.value.length === 6) {
                  confirmationResult.current.confirm(e.target.value);
                }
              }}
            />
          )}
          {authType === AuthTypes.EMAIL && (
            <>
              <TextField
                required
                label="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        //onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? (
                          <VisibilityOffRounded />
                        ) : (
                          <VisibilityRounded />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="text">
                <span
                  onClick={() => {
                    dispatch(setIsAuthenticating(false));
                    navigate('/forgot');
                  }}
                  className="clickable">
                  Forgot your password?
                </span>
              </Typography>
            </>
          )}
          <Typography variant="text">
            Don't have an account?{' '}
            <span
              onClick={() => {
                if (onToggleAuth && typeof onToggleAuth === 'function') {
                  onToggleAuth();
                  return;
                }
                navigate('/register');
              }}
              className="clickable">
              Create account
            </span>
            .
          </Typography>
        </Stack>
        <br />
        <LoadingButton
          id="sign-in-button"
          size="large"
          disabled={AuthTypes.NONE === authType}
          fullWidth
          type="submit"
          loadingPosition="start"
          loading={isWorking}
          variant="contained">
          LOGIN
        </LoadingButton>
      </div>
    </form>
  );
};

export default LoginForm;
