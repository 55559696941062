import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Swal from 'sweetalert2';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import {recordError, setIsAuthenticating} from '../../containers/App/actions';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect, useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import FunctionClient from '../../clients/Functions';
import {LoadingButton} from '@mui/lab';
import mixpanel from 'mixpanel-browser';
import RequestReferenceImage from '../../containers/RequestReferenceImage';
import Header from './header';
import ShippingMethod from './shippingMethod';
import Instructions from './instructions';
import HideFromProfile from './hideFromProfile';
import SelectProductDialog from './selectProductDialog';
import AddressForm from '../../containers/AddressForm';
import ProductImage from '../../components/ProductImage';
import {getAuth} from 'firebase/auth';

const PurchaseDoodleScreen = ({user}) => {
  const [loading, setLoading] = useState(true);
  const [doodler, setDoodler] = useState(null);
  const [address, setAddress] = useState(false);
  const [userPrivate, setUserPrivate] = useState(false);
  const [displayName, setDisplayName] = useState(
    user ? user.displayName || '' : '',
  );
  const [description, setDescription] = useState('');
  const [doodleFor, setDoodleFor] = useState('');
  const [doodleFrom, setDoodleFrom] = useState('');
  const [cardEntryComplete, setCardEntryComplete] = useState(false);
  const [working, setWorking] = useState(false);
  const [orderType] = useState('Personal');
  const [license, setLicense] = useState('Personal Use');
  const [referenceImage, setReferenceImage] = useState('');
  const [commissionPrice, setCommissionPrice] = useState(0);
  const [products, setProducts] = useState([]);
  const [selectProductVariant, setSelectProductVariant] = useState(null);
  const [tax, setTax] = useState(0);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const {doodlerId} = useParams();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const auth = useRef(getAuth()).current;

  useEffect(() => {
    if (user && auth.currentUser) {
      setEmail(auth.currentUser.email);
    }
  }, [user]);

  useEffect(() => {
    if (stripe && doodler) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: `Commission from ${doodler.displayName}`,
          amount: doodler.price * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        console.log(result);
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, doodler]);

  useEffect(() => {
    loadDoodlerAsync();
  }, []);

  useEffect(() => {
    if (doodler) {
      mixpanel.track('Doodler Purchase Page Viewed', {
        path: doodler.displayName,
      });
    }
  }, [doodler]);

  useEffect(() => {
    if (address) {
      FunctionClient.getTaxes({
        address,
        line_items: products.map(p => ({
          quantity: 1,
          product_tax_code: '20010',
          unit_price: p.retail_price / 100,
          discount: 0,
        })),
        shipping: 10,
        amount: products.reduce((acc, p) => acc + p.retail_price, 0) / 100,
      }).then(res => {
        setTax(res.tax.amount_to_collect * 100);
      });
    }
  }, [address, products]);

  const loadDoodlerAsync = async () => {
    setLoading(true);

    const docRef = doc(db, 'users', doodlerId);

    const docSnap = await getDoc(docRef);

    setCommissionPrice(parseInt(docSnap.data().price * 100, 10));

    setDoodler({
      ...docSnap.data(),
      id: docSnap.id,
    });
  };

  const handlePurchaseDoodleAsync = async e => {
    try {
      e.preventDefault();

      setWorking(true);

      mixpanel.track('Attempting Doodle Purchase', {
        doodler: doodler.displayName,
        amount: doodler.price,
      });

      stripe
        .createToken(elements.getElement(CardElement))
        .then(async ({token}) => {
          try {
            const res = await FunctionClient.createOrderAsync({
              ...token,
              price: commissionPrice,
              doodlerId: doodler.id,
              description,
              doodleFor,
              doodleFrom,
              userPrivate,
              public: !userPrivate,
              doodlerName: doodler.displayName,
              requester: user.uid,
              displayName,
              orderType,
              license,
              referenceImage,
              email,
            });

            setWorking(false);

            navigate(`/account/orders/${res.id}`);

            mixpanel.track('Doodle Purchased', {
              doodler: doodler.displayName,
              amount: doodler.price,
            });
          } catch (err) {
            alert(err.message);
            dispatch(recordError(err, 'purchase_error'));
            setWorking(false);
          }
        });
    } catch (err) {
      alert(err.message);
      setWorking(false);
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  const getSubtotal = () => {
    return (
      commissionPrice +
      products.reduce((acc, p) => acc + p.retail_price, 0) +
      tax +
      (shippingMethod ? shippingMethod.rate : 0)
    );
  };

  const shippingRequired = products.length > 0;

  const showPayment = () => {
    if (shippingRequired) {
      return Boolean(address);
    } else {
      return true;
    }
  };

  if (!doodler) return <LinearProgress />;

  return (
    <Container maxWidth="sm">
      <SelectProductDialog
        addressRequired
        onVariantSelected={v => {
          setSelectProductVariant(null);
          setProducts(products.concat([v]));
          Swal.fire('Success', `Added ${v.title} to your order.`, 'success');
        }}
        onClose={() => setSelectProductVariant(null)}
        open={Boolean(selectProductVariant)}
        product={selectProductVariant}
      />

      <Header doodler={doodler} />

      <Stack spacing={3}>
        <TextField
          inputProps={{
            readOnly: Boolean(user && user.displayName),
          }}
          required={!Boolean(user && user.displayName)}
          label="Your Name"
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
        />

        {user && (
          <TextField
            label="Email"
            required
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        )}

        <Instructions
          setDescription={setDescription}
          description={description}
          doodler={doodler}
        />

        <HideFromProfile
          setUserPrivate={setUserPrivate}
          userPrivate={userPrivate}
        />

        {!doodler.disableInstructions && (
          <RequestReferenceImage
            onImageAdded={image => setReferenceImage(image)}
          />
        )}

        {/*<CommissionCheckoutProducts*/}
        {/*  onItemSelected={item => {*/}
        {/*    setSelectProductVariant(item);*/}
        {/*  }}*/}
        {/*/>*/}

        <Box border className="stripe-box">
          <CardElement
            onChange={status => setCardEntryComplete(status.complete)}
            options={cardStyle(isMobile)}
          />
        </Box>

        <br />

        {shippingRequired && (
          <React.Fragment>
            <Stack direction="row" justifyContent="space-between">
              <Typography paragraph variant="h5">
                Shipping Address
              </Typography>
              {Boolean(address) && (
                <Typography
                  className="clickable"
                  onClick={() => setAddress(null)}
                  color="blue">
                  Edit
                </Typography>
              )}
            </Stack>
            <div style={{display: address ? 'none' : 'block'}}>
              <AddressForm
                onComplete={_address => {
                  setAddress(_address);
                }}
              />
            </div>
            <div>
              {Boolean(address) && (
                <Typography>
                  <Typography>{address.address}</Typography>
                  <Typography>
                    {address.city}, {address.state}
                  </Typography>
                </Typography>
              )}
            </div>
            <br />
          </React.Fragment>
        )}

        {shippingRequired && products.length && address && (
          <ShippingMethod
            onShippingMethodSelected={m => setShippingMethod(m)}
            products={products}
            address={address}
          />
        )}

        {showPayment() && (
          <React.Fragment>
            <TotalsBreakdown
              setSelectProductVariant={setSelectProductVariant}
              handleRemoveItem={item => {
                setProducts(products.filter(p => p.id !== item.id));
              }}
              tax={tax}
              shipping={shippingMethod ? shippingMethod.rate : 0}
              doodler={doodler}
              products={products}
              subtotal={getSubtotal() / 100}
            />
            <br />

            {user && (
              <LoadingButton
                onClick={handlePurchaseDoodleAsync}
                size="large"
                loading={working}
                disabled={!cardEntryComplete}
                variant="contained"
                type="submit">
                {working ? 'PROCESSING TRANSACTION' : 'COMMISSION GETADOODLE'}
              </LoadingButton>
            )}

            {paymentRequest && (
              <PaymentRequestButtonElement options={{paymentRequest}} />
            )}

            {!user && (
              <Button
                size="large"
                variant="contained"
                onClick={() => dispatch(setIsAuthenticating(true))}>
                LOGIN TO COMMISSION
              </Button>
            )}
          </React.Fragment>
        )}
      </Stack>
      <br />
      <Typography color="darkGrey" variant="caption">
        We'll authorize your payment and place a hold on your card until your
        Getadoodle is completed. Talent has 7 days to complete this Getadoodle.
        You'll receive an email when your order is ready.
      </Typography>
      <br />
      <br />
      <br />
    </Container>
  );
};

const TotalsBreakdown = ({
  products,
  doodler,
  subtotal,
  tax,
  handleRemoveItem,
  shipping,
}) => {
  return (
    <React.Fragment>
      <Typography variant="h5">
        <strong>YOUR ORDER</strong>
      </Typography>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Typography>Getadoodle Digital Commission</Typography>
          </Stack>
          <Typography>${doodler.price}</Typography>
        </Stack>
        {products.map(product => (
          <Stack
            key={product.id}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack alignItems="center" direction="row" spacing={2}>
              <div style={{width: 35}}>
                <ProductImage thumbnail={product.thumbnail} product={product} />
              </div>
              <Stack>
                <Typography>{product.title}</Typography>
                <Typography
                  className="pointer"
                  onClick={() => handleRemoveItem(product)}
                  variant="caption"
                  style={{color: 'red'}}>
                  Remove
                </Typography>
              </Stack>
            </Stack>
            <Typography>${product.retail_price / 100}</Typography>
          </Stack>
        ))}
        {products.length > 0 && (
          <React.Fragment>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Shipping</Typography>
              <Typography>${shipping / 100}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Tax</Typography>
              <Typography>${tax / 100}</Typography>
            </Stack>
          </React.Fragment>
        )}
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography color="darkGrey">
            <strong>Total</strong>
          </Typography>
          <Typography color="darkGrey">
            <strong>${subtotal}</strong>
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

const cardStyle = isMobile => ({
  style: {
    base: {
      fontSize: isMobile ? '16px' : '22px',
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: isMobile ? '28px' : '36px',
      height: isMobile ? 28 : 36,
      fontFamily: 'Saira, Avenir, Helvetica',
    },
  },
});

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(PurchaseDoodleScreen);
